import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useRef, useState, } from "react";
import { SVG_HEIGHT, SVG_WIDTH } from "./constants";
export default memo(function WordCloud({ onWordClick, words, ...rest }) {
    const [wordsToRender, setWordsToRender] = useState([]);
    const workerRef = useRef();
    useEffect(() => {
        const wordCloudWorker = new Worker(new URL("wordCloudWorker.js", import.meta.url), { type: "module" });
        wordCloudWorker.onmessage = ({ data }) => setWordsToRender(data);
        workerRef.current = wordCloudWorker;
        return () => wordCloudWorker.terminate();
    }, []);
    useEffect(() => {
        if (!workerRef.current) {
            console.error("workerRef.current is not set in Eri WordCloud");
            return;
        }
        workerRef.current.postMessage(words);
    }, [words]);
    if (!Object.keys(words).length)
        return;
    if (process.env.NODE_ENV !== "production" && wordsToRender.length) {
        const wordCount = Object.keys(words).length;
        const wordsNotRenderedCount = wordCount - wordsToRender.length;
        if (wordsNotRenderedCount) {
            const textToRender = new Set(wordsToRender.map(({ text }) => text));
            console.debug(`Eri WordCloud only rendered ${((wordsToRender.length / wordCount) *
                100).toFixed(1)}% of words.`, "Failed to render the following words:", Object.keys(words).filter((word) => !textToRender.has(word)));
        }
    }
    return (_jsx("svg", { ...rest, viewBox: `0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`, children: wordsToRender.map(({ rotate, size, text, weighting, x, y }) => (_jsx("g", { className: "fade-in word-cloud__word-container", transform: `rotate(${(rotate / Math.PI) * 180} ${x} ${y})`, children: _jsxs("text", { className: `word-cloud__word${onWordClick ? " word-cloud__word--clickable" : ""}`, onClick: onWordClick && (() => onWordClick(text, weighting)), style: {
                    "--word-from-x": `${x - SVG_WIDTH / 2}px`,
                    "--word-from-y": `${y - SVG_HEIGHT / 2}px`,
                }, fontSize: size, x: x, y: y, textAnchor: "middle", children: [_jsx("title", { children: weighting }), text] }) }, text))) }));
});
